.appHeader {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  align-items: center;
}